import {Table, Tbody, Td, Th, Thead, Tr} from "Components/Library/Table";
import {CsvResult} from "types/inbound-forecast-group";

export default function CsvUploadResultTable({result}: { result: CsvResult }) {
    return (
        <div className="overflow-y-auto">
            <b className="block mb-2">Upload result:</b>
            <Table>
                <Thead>
                    <Tr>
                        <Th>Success count</Th>
                        <Th>Failed count</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    <Tr>
                        <Td>{result.successCount}</Td>
                        <Td>{result.failedCount}</Td>
                    </Tr>
                </Tbody>
            </Table>
            {result.failedCount > 0 && (
                <div className="mt-4 overflow-y-auto max-h-96">
                    <b className="block text-red-900 mb-1">Failed reasons:</b>
                    <Table>
                        <Thead>
                            <Tr>
                                <Th>Line</Th>
                                <Th>Reason</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {Object.entries(result.failedReasons).map(([line, reason]) => (
                                <Tr key={line}>
                                    <Td>{line}</Td>
                                    <Td>{reason}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </div>
            )}
        </div>
    )
}
