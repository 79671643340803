import { PageTitle } from "Components";
import { useDocumentTitle } from "Hooks/useDocumentTitle";

type Props = {
    documentTitle?: string;
    title: string | React.ReactNode;
    subTitle?: string | React.ReactNode;
    metaData?: string | React.ReactElement | Array<string | React.ReactElement | undefined>;
    toolbar?: React.ReactElement | React.ReactElement[];
    className?: string;
}

const PageHeader = ({ title, subTitle, documentTitle, metaData, toolbar, className }: Props) => {
    let pageTitle = typeof title === "string" ? title : documentTitle;
    useDocumentTitle(pageTitle);

    return (
        <div className={"flex items-start justify-between w-full " + className}>
            <div className="mb-4">
                <PageTitle noBottomMargin>
                    {title}
                </PageTitle>
                {subTitle && (
                    <div className="text-gray-600 mt-1">{subTitle}</div>
                )}
                {metaData && (
                    <div className="text-gray-600 italic mt-1 text-sm">
                        {Array.isArray(metaData) ? metaData.filter(data => !!data).map((data, index) => (
                            <div key={index}>{data}</div>
                        )) : metaData}
                    </div>
                )}
            </div>
            <div className="flex items-center gap-2">
                {toolbar}
            </div>
        </div>
    )
}

export default PageHeader;
