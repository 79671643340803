import papa from "papaparse";

export const parseCsv = (file: File, requiredColumns?: string[]) => {
    return new Promise((resolve, reject) => {
        papa.parse(file, {
            header: true,
            complete: (result) => {
                if (requiredColumns) {
                    if (!result.meta.fields) {
                        reject('No columns found');
                        return;
                    }
                    
                    const missingColumns = requiredColumns.filter(column => !result.meta.fields?.includes(column));
                    if (missingColumns.length) {
                        reject(`Missing columns: ${missingColumns.join(', ')}`);
                        return;
                    }
                }
                resolve(result);
            }
        });
    })
}