import { PiWarningFill } from "react-icons/pi"
import { PurchaseMethodEnum, Supplier } from "types/supplier"
import { Tooltip } from "@material-tailwind/react"
import { Table, Thead, Tbody, Tr, Th, Td } from 'Components';
import { useClickableNavigate } from "utils/PathConstants";


export default function SupplierTable({ suppliers }: { suppliers: Supplier[] }) {
    const {
        onMouseDownHandler,
        onMouseMoveHandler,
        navigateToSupplier
    } = useClickableNavigate();

    return (
        <Table>
            <Thead>
                <Tr>
                    <Th className="w-20">Code</Th>
                    <Th>Name</Th>
                    <Th>E-mail</Th>
                    <Th>Phone</Th>
                    <Th>Purchase Method</Th>
                </Tr>
            </Thead>
            <Tbody>
                {suppliers.length === 0 && (
                    <Tr>
                        <Td colSpan={5}>
                            No suppliers found
                        </Td>
                    </Tr>
                )}
                {suppliers.map((supplier, index) => (
                    <Tr
                        key={index}
                        onMouseDown={onMouseDownHandler}
                        onMouseMove={onMouseMoveHandler}
                        onClick={(e) => navigateToSupplier(e, supplier.montaCode)}
                        className="cursor-pointer hover:bg-gray-50"
                    >
                        <Td className="w-20">{supplier.montaCode}</Td>
                        <Td className="whitespace-nowrap flex items-center">
                            {supplier.name}
                            {!supplier.profortoData.exactGuid && (
                                <Tooltip content="Missing ExactGuid">
                                    <div>
                                        <PiWarningFill className="ml-2 mb-0.5 -mr-8 text-red-500 w-5 h-5" />
                                    </div>
                                </Tooltip>
                            )}
                        </Td>
                        <Td>{supplier.email}</Td>
                        <Td>{supplier.phone}</Td>
                        <Td>{PurchaseMethodEnum[supplier.profortoData.purchaseMethod]}</Td>
                    </Tr>
                ))}
            </Tbody>
        </Table>
    )
}
