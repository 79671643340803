import {RiContactsBook2Fill} from "react-icons/ri";
import {GoPackageDependencies} from "react-icons/go";
import {MdPallet, MdSell, MdFormatListNumbered, MdCallSplit} from "react-icons/md";
import {FaTshirt} from "react-icons/fa";
import {Permissions} from "Contexts";

type Module = {
    name: string,
    link: string,
    icon: any,
    requiredPermissions?: Permissions[]
}

const modules = [{
        name: 'Purchase Orders',
        link: '/purchase-orders',
        icon: MdFormatListNumbered,
        requiredPermissions: [Permissions.READ_PURCHASE_ORDERS]
    }, {
        name: 'Inbound Forecasts',
        link: '/inbound-forecasts',
        icon: MdPallet,
        requiredPermissions: [Permissions.READ_INBOUND_FORECASTS]
    }, {
        name: 'Sales Orders',
        link: '/sales-orders',
        icon: MdSell,
        requiredPermissions: [Permissions.READ_SALES_ORDERS]
    }, {
        name: 'RMAs',
        link: '/rmas',
        icon: GoPackageDependencies,
        requiredPermissions: [Permissions.READ_RMAS]
    }, {
        name: 'Inventory',
        link: '/inventory',
        icon: FaTshirt,
        requiredPermissions: [Permissions.READ_STOCKS]
    },
    {
        name: 'Suppliers',
        link: '/suppliers',
        icon: RiContactsBook2Fill,
        requiredPermissions: [Permissions.READ_SUPPLIERS]
    },
    {
        name: 'Auto Split',
        link: '/auto-split',
        icon: MdCallSplit,
        requiredPermissions: [Permissions.AUTO_SPLIT]
    },
]

export const getModulesForUser = (userPermissions: Permissions[]): Module[] => {
    if (!userPermissions) return []
    return modules.filter(module => {
        return module.requiredPermissions.every(requiredPermission => userPermissions.includes(requiredPermission))
    })
}
