import SupplierTable from "Components/Suppliers/SupplierTable";
import { Supplier } from "types/supplier";
import {ChangeEvent, useEffect, useState} from "react";
import {useApiClient, useMessage} from '../Contexts';
import {Button, Loading, PageHeader, Table, Tbody, Td, TextInput, Th, Thead, Tr} from 'Components';
import {parseCsv} from "../utils/csv";
import {useAuth0} from "@auth0/auth0-react";
import {RequisitionUploadRow} from "../types/stock";
import Modal from "../Components/Library/Modal";
import CsvUploadResultTable from "../Components/InboundForecastGroups/CsvUploadResultTable";
import {CsvResult} from "../types/inbound-forecast-group";

export default function SupplierList() {
    const {
        user,
    } = useAuth0();
    const {handleError} = useMessage();
    const apiClient = useApiClient();
    
    const [searchQuery, setSearchQuery] = useState('')
    const [suppliers, setSuppliers] = useState(null as Supplier[] | null)
    const [filteredSuppliers, setFilteredSuppliers] = useState(suppliers)

    const [requisitions, setRequisitions] = useState([] as RequisitionUploadRow[]);
    const [ignoredRequisitions, setIgnoredRequisitions] = useState([] as RequisitionUploadRow[]);
    const [showRequisitionUploadDialog, setShowRequisitionUploadDialog] = useState(false);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [uploadRequisitionResult, setUploadRequisitionResult] = useState(null as CsvResult | null);
    

    useEffect(() => {
        const fetchSuppliers = async () => {
            try {
                const { data } = await apiClient.getSuppliers();
                if (data) {
                    setSuppliers(data);
                }
            } catch (error) {
                console.error("Error fetching suppliers:", error);
            }
        };

        fetchSuppliers();
    }, [apiClient]);

    useEffect(() => {
        if (!suppliers) return

        const filtered = suppliers.filter(supplier =>
            supplier.montaCode.toLowerCase().includes(searchQuery.toLowerCase()) ||
            supplier.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            supplier.email?.toLowerCase().includes(searchQuery.toLowerCase())
        )
        setFilteredSuppliers(filtered)
    }, [searchQuery, suppliers])

    const handleRequisitionUploadClicked = () => {
        setShowRequisitionUploadDialog(true);
    }

    const handleRequisitionUploadClosed = () => {
        setRequisitions([]);
        setIgnoredRequisitions([]);
        setUploadLoading(false);
        setShowRequisitionUploadDialog(false);
        setUploadRequisitionResult(null);
    }

    const handleRequisitionsFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
        setRequisitions([])
        setIgnoredRequisitions([])
        const file = e.target.files?.[0];
        if (!file) return;

        try {
            const data = []
            const ignoredRows = [];
            const rows = await parseCsv(file, ['Sku', 'Ean', 'Quantity']);
            let lineNumber = 0;
            for (const row of (rows as any).data as RequisitionUploadRow[]) {
                row.lineNumber = ++lineNumber;
                if (!row.Sku && !row.Ean) {
                    ignoredRows.push(row);
                    continue;
                }
                if (!row.Ean) {
                    row.Ean = '';
                }
                data.push(row)
            }
            setRequisitions(data);
            setIgnoredRequisitions(ignoredRows);
        } catch (error: any) {
            console.error("Error parsing CSV:", error);
            handleError("Error parsing CSV:", error);
        }
    }

    const uploadRequisitions = async () => {
        if (!requisitions.length) return;
        setUploadLoading(true);

        if (!user) return; // Should not happen
        if (!user.email) {
            console.error("User does not have an email address, cannot cancel line item");
            return;
        }

        const author = user.email;

        try {
            const { data } = await apiClient.bulkUploadRequisitions(requisitions, author);
            setUploadRequisitionResult(data);
        } catch (error) {
            console.error("Error postponing inbound forecasts:", error);
            setShowRequisitionUploadDialog(false);
        } finally {
            setUploadLoading(false);
        }
    }

    if (filteredSuppliers === null) return (
        <Loading />
    )

    return (
        <>
            <PageHeader
                title="Suppliers"
                toolbar={(
                    <div>
                        <Button
                            onClick={handleRequisitionUploadClicked}
                            type="cta"
                            className="mr-2"
                        >
                            Bulk Upload Requisitions
                        </Button>
                    </div>
                )}
            />

            <div className="mb-4">
                <TextInput
                    placeHolder="Search by name or email"
                    value={searchQuery}
                    onChange={setSearchQuery}
                    withClearButton
                />
            </div>

            {showRequisitionUploadDialog && (
                <Modal title={`Bulk upload requisitions`} onClose={handleRequisitionUploadClosed}>
                    {uploadLoading
                        ? <Loading />
                        : (uploadRequisitionResult
                                ? <CsvUploadResultTable result={uploadRequisitionResult} />
                                : (
                                    <div>
                                        <p className="mb-2">Upload a csv file to start.</p>
                                        <p className="text-gray-500 italic mb-4">Required columns are:<br/> 
                                            <code>'Quantity'</code>
                                            and either <br/>
                                            <code>'Sku'</code>
                                            or <br/>
                                            <code>'Ean'</code>
                                        </p>
                                        <input
                                            type="file"
                                            accept=".csv"
                                            onChange={handleRequisitionsFileUpload}
                                        />
                                        {ignoredRequisitions.length > 0 && (
                                            <div className="mt-4">
                                                <b className="block text-red-900 mb-1">Ignored rows:</b>
                                                <Table>
                                                    <Thead>
                                                        <Th className="w-8">Line</Th>
                                                        <Th>Quantity</Th>
                                                        <Th>Sku</Th>
                                                        <Th>Ean</Th>
                                                    </Thead>
                                                    <Tbody>
                                                        {ignoredRequisitions.map(row => (
                                                            <Tr key={row.lineNumber}>
                                                                <Td className="text-right w-8">{row.lineNumber}</Td>
                                                                <Td>{row.Quantity}</Td>
                                                                <Td>{row.Sku}</Td>
                                                                <Td>{row.Ean}</Td>
                                                            </Tr>
                                                        ))}
                                                    </Tbody>
                                                </Table>
                                            </div>
                                        )}
                                        {requisitions.length > 0 && (
                                            <div className="mt-4 overflow-y-auto max-h-96">
                                                <b className="block mb-1">Parsed rows:</b>
                                                <Table>
                                                    <Thead>
                                                        <Th className="w-8">Line</Th>
                                                        <Th>Quantity</Th>
                                                        <Th>Sku</Th>
                                                        <Th>Ean</Th>
                                                    </Thead>
                                                    <Tbody>
                                                        {requisitions.map(row => (
                                                            <Tr key={row.lineNumber}>
                                                                <Td className="text-right w-8">{row.lineNumber}</Td>
                                                                <Td>{row.Quantity}</Td>
                                                                <Td>{row.Sku}</Td>
                                                                <Td>{row.Ean}</Td>
                                                            </Tr>
                                                        ))}
                                                    </Tbody>
                                                </Table>
                                                <div className="flex justify-end mt-4">
                                                    <Button onClick={uploadRequisitions} type='cta'>
                                                        Upload requisitions
                                                    </Button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )
                        )
                    }
                </Modal>
            )}

            <div className="overflow-x-auto shadow-md sm:rounded-lg w-full">
                <SupplierTable suppliers={filteredSuppliers} />
            </div>
        </>
    );
}
