import React, {useEffect, useState} from "react";
import {useApiClient, useMessage} from '../Contexts';
import {Button, Card, Loading, NumberInput, PageHeader} from 'Components';
import {AutoSplitStateDto} from "../types/auto-split";

export default function AutoSplit() {

    const apiClient = useApiClient();
    const [state, setState] = useState<AutoSplitStateDto | null>(null);
    const [loadingState, setLoadingState] = useState(true);
    const [splitAmount, setSplitAmount] = useState(0);
    const {handleError} = useMessage();

    const fetchSplitState = async (keepPolling: boolean) => {
        try {
            const {data} = await apiClient.getAutoSplitState();
            setState(data);

            if (keepPolling) {
                setLoadingState(true);
                const shouldKeepPolling = !!data && (!data.ready || (data.started && !data.completed));
                setTimeout(() => fetchSplitState(shouldKeepPolling), 1_000);
            } else {
                setLoadingState(false);
            }
        } catch (error) {
            console.error("Error fetching rateInfos:", error);
        }
    };

    useEffect(() => {
        // First page load fetch
        fetchSplitState(false);
    }, []);

    const handleInboundsCompleted = async () => {
        setLoadingState(true);
        await apiClient.startAutoSplitPreparation();
        setTimeout(() => fetchSplitState(true), 1_000);
    };

    const handleAutoSplit = async () => {
        if (!state || splitAmount !== state.count) {
            handleError(
                'The split amount filled in does not reflect the amount that is expected to be split. Please check the amount and try again.'
            );
            return;
        }
        setLoadingState(true);
        await apiClient.startAutoSplit(splitAmount);
        setTimeout(() => fetchSplitState(true), 1_000);
    };

    const handleCancel = async () => {
        setLoadingState(true);
        await apiClient.cancelAutoSplit();
        setTimeout(() => fetchSplitState(true), 1_000);
    };

    return <>
        <PageHeader title="Auto Split" className="flex-col items-center"/>
        {!state && ( // Initial state
            <div className="h-96 w-full flex flex-col items-center justify-center">
                <Button
                    className="font-extrabold text-xl bg-red-800"
                    onClick={handleInboundsCompleted}
                    type="red"
                    disabled={loadingState}
                >
                    <div className="m-7">
                        Have all inbounds for today been processed? <br/> <br/>
                        Click here to start the preparation for automatic splitting
                        of unfulfillable Monta Orders
                    </div>
                </Button>
            </div>
        )}

        {state && !state.ready && ( // Awaiting preparation
            <Loading text="Preparing AutoSplit"/>
        )}

        {state && state.ready && !state.started && ( // Ready to start 
            <div className="flex flex-col items-center w-full">
                <Card className="flex flex-col items-center mt-20 p-10">
                    <p className="text-2xl">{state.count}</p>
                    <div className="mt-2 text-gray-500">MontaOrders have been marked for AutoSplit. To continue, fill
                        this number in below:
                    </div>
                    <div className="flex flex-row justify-center items-center mt-5 gap-3">
                        <NumberInput
                            className="w-20"
                            min={0}
                            step={1}
                            value={0}
                            onChange={(value) => setSplitAmount(value)}
                        />
                        <Button
                            onClick={handleAutoSplit}
                            type="cta"
                            disabled={loadingState}
                        >
                            Start AutoSplit
                        </Button>
                        <Button
                            onClick={handleCancel}
                            type="red"
                            disabled={loadingState}
                        >
                            Cancel
                        </Button>
                    </div>
                </Card>
            </div>
        )}

        {state && state.started && !state.completed && ( // In progress
            <Loading text="Currently Splitting"/>
        )}

        {state && state.completed && ( // Completed
            <div className="flex flex-col items-center w-full">
                <p className="text-2xl text-red-700 mt-28">AutoSplit has been completed today</p>
            </div>
        )}
    </>
}
