import {ProfortoData, Supplier, SupplierAsOption} from "types/supplier";
import {StatusCodes} from "http-status-codes";
import {
    InboundForecast,
    InboundForecastGroup,
    InboundForecastGroupsSearchCriteria,
    PostponeInboundForecastDto,
    CsvResult
} from "types/inbound-forecast-group";
import {PrintNodePrintersDto} from "types/printers";
import {
    RequisitionInput,
    RequisitionStock, RequisitionUploadRow,
    Stock,
    StockSearchCriteria,
    UpdateRequisitionQuantity
} from 'types/stock';
import {Address, MontaOrderEvent, SalesOrder, SalesOrderSearchCriteria, SalesOrderView} from "types/sales-order";
import {RateInfoOverview} from "types/monta-rates";
import moment from "moment";
import {PurchaseOrder, PurchaseOrderSearchCriteria} from "types/purchase-order";
import {Rma, RmaSearchCriteria} from "types/rma";
import {MontaOrder} from "types/sales-order/montaOrder";
import {CommentModel, ErrorEventModel} from "types/shared";
import {AutoSplitStateDto} from "../types/auto-split";

export interface ApiResponse<T> {
    data: T | null;
    status: number;
}

export interface Pagination {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    page: number;
    pageSize: number;
    totalItems: number;
    totalPages: number;
}

export interface PaginationResult<T> {
    data: T[];
    pagination: Pagination;
}

export interface CancelLineItem {
    webshopOrderLineId: string;
    sku: string;
    quantity: number;
}


type ApiClientOptions = {
    baseUrl?: string;
    handleError: (errorMessage: string, error?: unknown) => void;
    handleLoading?: (loadingMessage: string) => { remove: () => void };
    handleSuccess?: (successMessage: string) => { remove: () => void };
    getAccessTokenSilently: () => Promise<string | undefined>;
    isLoading: boolean;
    isAuthenticated: boolean;
}

class ApiClient {
    private baseUrl: string;
    private handleError: (errorMessage: string, error?: unknown) => void;
    private handleLoading?: (loadingMessage: string) => { remove: () => void };
    private handleSuccess?: (successMessage: string) => { remove: () => void };
    private getAccessTokenSilently: () => Promise<string | undefined>;
    public isLoading: boolean;
    public isAuthenticated: boolean;

    constructor({ baseUrl, handleError, handleLoading, handleSuccess, getAccessTokenSilently, isLoading, isAuthenticated }: ApiClientOptions) {
        this.baseUrl = baseUrl ?? this.getBaseUrl() + '/api';
        this.handleError = handleError;
        this.handleLoading = handleLoading;
        this.handleSuccess = handleSuccess;
        this.getAccessTokenSilently = getAccessTokenSilently;
        this.isLoading = isLoading;
        this.isAuthenticated = isAuthenticated;
    }

    private getBaseUrl(): string {
        const url = window.location.origin;

        switch (url) {
            case 'https://procurement.staging.proforto.app':
            case 'https://staging.proforto.app':
                return 'https://procurement-api.staging.proforto.app'

            case 'https://procurement.proforto.app':
            case 'https://proforto.app':
                return 'https://procurement-api.proforto.app'

            default:
                return 'http://localhost:5290'
        }
    }

    private async request<T>(
        endpoint: string,
        { method = 'GET', body = null }: { method?: string, body?: any } = {}
    ): Promise<ApiResponse<T>> {
        if (!this.isLoading && !this.isAuthenticated) {
            console.error('User is not authenticated');
            return { data: null, status: 401 }; // Using return to prevent logging while being redirected to login page.
        }
        const token = await this.getAccessTokenSilently();

        const headers: HeadersInit = { 'Content-Type': 'application/json' };
        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }

        const config: RequestInit = {
            method,
            headers,
            body: body ? JSON.stringify(body) : null,
        };

        const response = await fetch(`${this.baseUrl}${endpoint}`, config);
        if (!response.ok) {
            const errorMessage = await response.text();
            console.error(`API request failed: ${method} (${endpoint}) ${response.statusText} ${errorMessage}`);
            throw new Error(errorMessage)
        }

        const rawData = await response.text();
        const data = rawData ? JSON.parse(rawData) : null as T | null;
        return { data, status: response.status };
    }

    public async getSuppliers(): Promise<ApiResponse<Supplier[]>> {
        try {
            const result = await this.request<Supplier[]>('/Suppliers');
            return result
        } catch (e) {
            console.error('Error fetching suppliers', e);
            this.handleError?.('Error fetching suppliers', e);
            return { data: [], status: 500 };
        }
    }

    public async getSuppliersAsOptions(): Promise<ApiResponse<SupplierAsOption[]>> {
        try {
            const result = await this.request<SupplierAsOption[]>('/Suppliers/options');
            return result;
        } catch (e) {
            console.error('Error fetching suppliers as options', e);
            this.handleError?.('Error fetching suppliers as options', e);
            return { data: [], status: 500 };
        }
    }

    public async getSupplierByCode(montaCode: string): Promise<ApiResponse<Supplier>> {
        try {
            const result = await this.request<Supplier>(`/Suppliers/${montaCode}`);
            return result;
        } catch (e) {
            console.error('Error fetching supplier', e);
            this.handleError?.(`Error fetching supplier '${montaCode}'`, e);
            return { data: null, status: 500 };
        }
    }

    public async updateSupplierData(montaCode: string, data: ProfortoData): Promise<ApiResponse<Supplier>> {
        const loadingMessage = this.handleLoading?.('Updating supplier');

        try {
            const result = await this.request<Supplier>(`/Suppliers/${montaCode}`, { method: 'PUT', body: data });
            if (result.status === StatusCodes.OK) {
                this.handleSuccess?.('Supplier data updated');
            }

            return result;
        } catch (e) {
            console.error('Error updating supplier', e);
            this.handleError?.('Error updating supplier', e);
            return { data: null, status: 500 };
        } finally {
            loadingMessage?.remove();
        }
    }

    public async printInBoundForecastGroupLineItems(poNumber: string, lineItems: InboundForecast[], printerId: number): Promise<ApiResponse<boolean>> {
        const loadingMessage = this.handleLoading?.('Printing labels');

        try {
            const result = await this.request<boolean>(`/InboundForecastGroups/${encodeURIComponent(poNumber)}/PrintProductLabels`, {
                method: 'POST', body: {
                    lineItems,
                    printerId
                }
            });
            if (result.status === StatusCodes.OK) {
                this.handleSuccess?.('Labels printed');
            }
            return result;
        } catch (e) {
            console.error('Error printing labels', e);
            this.handleError?.('Error printing labels', e);
            return { data: null, status: 500 };
        } finally {
            loadingMessage?.remove();
        }
    }

    public async getAvailablePrinters(): Promise<ApiResponse<PrintNodePrintersDto[]>> {
        try {
            return await this.request<PrintNodePrintersDto[]>(`/Printers`, { method: 'GET' });
        } catch (e) {
            console.error('Error fetching available printers', e);
            this.handleError?.('Error fetching available printers', e);
            return { data: [], status: 500 };
        }
    }

    public async getInboundForecastGroups(searchCriteria: InboundForecastGroupsSearchCriteria, page: number, pageSize: number): Promise<ApiResponse<PaginationResult<InboundForecastGroup>>> {
        try {
            const result = await this.request<PaginationResult<InboundForecastGroup>>(`/InboundForecastGroups?page=${page}&pageSize=${pageSize}`,
                { method: 'POST', body: searchCriteria }
            );
            return result;
        } catch (e) {
            console.error('Error fetching inbound forecast groups', e);
            this.handleError?.('Error fetching inbound forecasts groups', e);
            return { data: null, status: 500 };
        }
    }

    public async getInboundForecastNeedsAttentionCount() {
        try {
            const result = await this.request<number>(`/InboundForecastGroups/count/needs-attention`,
                { method: 'GET' }
            );
            return result;
        } catch (e) {
            console.error('Error fetching inbound forecast groups that need attention count', e);
            this.handleError?.('Error fetching inbound forecast groups that need attention count', e);
            return { data: null, status: 500 };
        }
    }

    public async getInboundForecastGroup(purchaseOrderReference: string): Promise<ApiResponse<InboundForecastGroup>> {
        try {
            const result = await this.request<InboundForecastGroup>(`/InboundForecastGroups/${purchaseOrderReference}`);
            return result;
        } catch (e) {
            console.error('Error fetching inbound forecast group', e);
            this.handleError?.('Error fetching inbound forecast group', e);
            return { data: null, status: 500 };
        }
    }

    public async postponeInboundForecastLines(inboundForecasts: PostponeInboundForecastDto[], author: string): Promise<ApiResponse<CsvResult>> {
        const loadingMessage = this.handleLoading?.('Postponing inbound forecasts');
        try {
            return this.request<CsvResult>(`/InboundForecastGroups/Postpone?author=${author}}`, {
                method: 'POST',
                body: inboundForecasts
            });
        }
        catch (e) {
            console.error('Error postponing inbound forecasts', e);
            this.handleError?.('Error postponing inbound forecasts', e);
            return { data: null, status: 500 };
        } finally {
            loadingMessage?.remove();
        }
    }

    public async cancelInboundForecastLines(purchaseOrderReference: string, groupId: string, inboundForecastIds: number[]): Promise<ApiResponse<InboundForecastGroup>> {
        const loadingMessage = this.handleLoading?.('Cancelling inbound forecasts');
        try {
            await this.request<CsvResult>(`/InboundForecastGroups/${groupId}/Cancel`, {
                method: 'POST',
                body: inboundForecastIds
            });
            const remaining = await this.getInboundForecastGroup(purchaseOrderReference);
            this.handleSuccess?.('Inbound forecasts cancelled');
            return remaining;
        } catch (e) {
            console.error('Error cancelling inbound forecasts', e);
            this.handleError?.('Error cancelling inbound forecasts', e);
            return { data: null, status: 500 };
        } finally {
            loadingMessage?.remove();
        }
    }

    public async postponeInboundForecasts(rows: PostponeInboundForecastDto[], author: string,): Promise<ApiResponse<CsvResult>> {
        const loadingMessage = this.handleLoading?.('Postponing inbound forecast groups');
        try {
            const result = await this.request<CsvResult>(`/InboundForecastGroups/Postpone/Bulk`, {
                method: 'POST',
                body: {
                    postpones: rows,
                    author
                }
            });
            return result;
        } catch (e) {
            console.error('Error postponing inbound forecast group', e);
            this.handleError?.('Error postponing inbound forecast group', e);
            return { data: null, status: 500 };
        } finally {
            loadingMessage?.remove();
        }
    }

    public async postponeInboundProducts(rows: PostponeInboundForecastDto[], author: string): Promise<ApiResponse<CsvResult>> {
        const loadingMessage = this.handleLoading?.('Postponing inbound forecast groups');
        try {
            const result = await this.request<CsvResult>(`/InboundForecastGroups/Postpone/Products`, {
                method: 'POST',
                body: {
                    postpones: rows,
                    author
                }
            });
            return result;
        } catch (e) {
            console.error('Error postponing inbound forecast group', e);
            this.handleError?.('Error postponing inbound forecast group', e);
            return { data: null, status: 500 };
        } finally {
            loadingMessage?.remove();
        }
    }

    public async getStocks(searchCriteria: StockSearchCriteria, page: number, pageSize: number): Promise<ApiResponse<PaginationResult<Stock>>> {
        try {
            const result = await this.request<PaginationResult<Stock>>(`/Stocks?page=${page}&pageSize=${pageSize}`,
                { method: 'POST', body: searchCriteria }
            );
            return result;
        } catch (e) {
            console.error('Error fetching stocks', e);
            this.handleError?.('Error fetching stocks', e);
            return { data: null, status: 500 };
        }
    }

    public async pollMontaStocks(skus: string[]): Promise<ApiResponse<null>> {
        try {
            await this.request(`/Stocks/pollBySkus`, {
                method: 'POST',
                body: skus
            });
            this.handleSuccess?.('Skus added to poll queue, it might take a few minutes before the product has been updated');
            return { data: null, status: 200 };
        } catch (e) {
            console.error('Error adding skus to poll queue', e);
            this.handleError?.('Error adding skus to poll queue', e);
            return { data: null, status: 500 };
        }
    }

    public async getStocksBySupplierCode(id: string): Promise<ApiResponse<Stock[]>> {
        try {
            const result = await this.request<Stock[]>(`/suppliers/${id}/stocks`);
            return result;
        } catch (e) {
            console.error('Error fetching stocks', e);
            this.handleError?.('Error fetching stocks', e);
            return { data: null, status: 500 };
        }
    }

    public async getSalesOrders(searchCriteria: SalesOrderSearchCriteria, page: number, pageSize: number): Promise<ApiResponse<PaginationResult<SalesOrder>>> {
        try {
            const result = await this.request<PaginationResult<SalesOrder>>(`/SalesOrders?page=${page}&pageSize=${pageSize}`,
                { method: 'POST', body: searchCriteria }
            );
            return result;
        } catch (e) {
            console.error('Error fetching sales orders', e);
            this.handleError?.('Error fetching sales orders', e);
            return { data: null, status: 500 };
        }
    }

    public async getSalesOrdersNeedsAttentionCount(): Promise<ApiResponse<number>> {
        try {
            const result = await this.request<number>(`/SalesOrders/count/needs-attention`,
                { method: 'GET' }
            );
            return result;
        } catch (e) {
            console.error('Error fetching sales orders that need attention count', e);
            this.handleError?.('Error fetching sales orders that need attention count', e);
            return { data: null, status: 500 };
        }
    }

    public async getAutomaticRequisitionStocksBySupplier(montaCode: string): Promise<ApiResponse<RequisitionStock[]>> {
        try {
            const result = await this.request<RequisitionStock[]>(`/suppliers/${montaCode}/automatic-stock-requisitions-to-purchase`);
            return result;
        } catch (e) {
            console.error('Error fetching automatic stock to purchase', e);
            this.handleError?.('Error fetching automatic stock to purchase', e);
            return { data: null, status: 500 };
        }
    }

    public async getRequisitionsBySupplierCode(id: string): Promise<ApiResponse<RequisitionStock[]>> {
        try {
            const result = await this.request<RequisitionStock[]>(`/suppliers/${id}/requisitions`);
            return result;
        } catch (e) {
            console.error('Error fetching the manual requisitions', e);
            this.handleError?.('Error fetching the manual requisitions', e);
            return { data: null, status: 500 };
        }
    }

    public async addRequisitionsBySupplierCode(id: string, requisitions: RequisitionInput[]): Promise<ApiResponse<RequisitionStock[]>> {
        const loadingMessage = this.handleLoading?.('Adding requisition');
        try {
            const result = await this.request<RequisitionStock[]>(`/suppliers/${id}/requisitions`, { method: 'POST', body: requisitions });
            this.handleSuccess?.(`Requisition${requisitions.length ? 's' : ''} added`);
            return result;
        } catch (e) {
            console.error('Error adding manual requisitions', e);
            this.handleError?.('Error adding manual requisitions', e);
            return { data: null, status: 500 };
        } finally {
            loadingMessage?.remove();
        }
    }

    public async removeRequisitionsBySupplierCode(id: string, requisitionIds: string[]): Promise<ApiResponse<RequisitionStock[]>> {
        const loadingMessage = this.handleLoading?.('Removing requisition');
        try {
            const result = await this.request<RequisitionStock[]>(`/suppliers/${id}/requisitions`, {
                method: 'DELETE',
                body: requisitionIds
            });
            this.handleSuccess?.(`Requisition${result.data?.length ? 's' : ''} removed`);
            return result;
        } catch (e) {
            console.error('Error removing manual requisitions', e);
            this.handleError?.('Error removing manual requisitions', e);
            return { data: null, status: 500 };
        } finally {
            loadingMessage?.remove();
        }
    }

    public async updateRequisitionQuantityBySupplierCode(montaCode: string, updatedRequisition: UpdateRequisitionQuantity): Promise<ApiResponse<RequisitionStock[]>> {
        const loadingMessage = this.handleLoading?.('Updating requisition quantity');
        try {
            const result = await this.request<RequisitionStock[]>(`/suppliers/${montaCode}/requisitions`, {
                method: 'PUT',
                body: {
                    ...updatedRequisition
                }
            });
            this.handleSuccess?.(`Requisition quantity updated`);
            return result;
        } catch (e) {
            console.error('Error updating manual requisitions', e);
            this.handleError?.('Error updating manual requisitions', e);
            return { data: null, status: 500 };
        } finally {
            loadingMessage?.remove();
        }
    }
    
    public async bulkUploadRequisitions(requisitions: RequisitionUploadRow[], author: string): Promise<ApiResponse<CsvResult>> {
        const loadingMessage = this.handleLoading?.('Uploading requisitions');
        try {
            const result = await this.request<CsvResult>(`/suppliers/BulkUploadRequisitions?author=${author}`, {
                method: 'POST',
                body: requisitions
            });
            this.handleSuccess?.('Requisitions uploaded');
            return result;
        } catch (e) {
            console.error('Error uploading requisitions', e);
            this.handleError?.('Error uploading requisitions', e);
            return { data: null, status: 500 };
        } finally {
            loadingMessage?.remove();
        }
    }

    public async getSalesOrderView(mageReference: string): Promise<ApiResponse<SalesOrderView>> {
        try {
            const result = await this.request<SalesOrderView>(`/SalesOrders/${mageReference}`);
            return result;
        } catch (e) {
            console.error('Error fetching sales order', e);
            this.handleError?.('Error fetching sales order', e);
            return { data: null, status: 500 };
        }
    }

    public async getMontaOrderEvents(webshopOrderId: string): Promise<ApiResponse<MontaOrderEvent[]>> {
        try {
            const result = await this.request<MontaOrderEvent[]>(`/MontaOrders/${webshopOrderId}/events`);
            return result;
        }
        catch (e) {
            console.error('Error fetching Monta order events', e);
            this.handleError?.('Error fetching Monta order events', e);
            return { data: null, status: 500 };
        }
    }

    public async cancelOrders(
        webshopOrderIds: string[],
        author: string,
        reason: string
    ): Promise<ApiResponse<SalesOrderView>> {
        const loadingMessage = this.handleLoading?.('Cancelling orders');

        try {
            const res = await this.request<SalesOrderView>(`/MontaOrders/cancelOrders`,
                {
                    method: 'POST',
                    body: {
                        webshopOrderIds,
                        author,
                        reason
                    }
                }
            );

            this.handleSuccess?.('Orders have been cancelled');
            return res;
        } catch (e) {
            console.error('Error Cancelling orders', e);
            this.handleError?.('Error Cancelling orders', e);
            return { data: null, status: 500 };
        } finally {
            loadingMessage?.remove();
        }
    }

    public async cancelLineItem(
        montaWebshopOrderId: string,
        author: string,
        reason: string,
        lineItem?: CancelLineItem
    ): Promise<ApiResponse<SalesOrderView>> {
        const loadingMessage = this.handleLoading?.(lineItem
            ? `Cancelling line item${lineItem.sku ? ' with Sku "' + lineItem.sku + '"' : '"s'}`
            : 'Cancelling all line items'
        );

        try {
            const res = await this.request<SalesOrderView>(`/MontaOrders/${montaWebshopOrderId}/cancelItems`,
                {
                    method: 'POST',
                    body: {
                        author,
                        reason,
                        items: lineItem ? [lineItem] : []
                    }
                }
            );

            this.handleSuccess?.(lineItem
                ? `Line item${lineItem.sku ? ' with Sku "' + lineItem.sku + '" has' : 's have'} been cancelled`
                : 'All line items have been cancelled'
            );

            return res;
        } catch (e) {
            console.error(lineItem
                ? `Error Cancelling line item${lineItem.sku ? ' with SKU ' + lineItem.sku : 's'}`
                : 'Error Cancelling all line items'
                , e

            );
            this.handleError?.(lineItem
                ? `Error Cancelling line item${lineItem.sku ? ' with SKU ' + lineItem.sku : 's'}`
                : 'Error Cancelling all line items'
                , e
            );

            return { data: null, status: 500 };
        } finally {
            loadingMessage?.remove();
        }
    }

    public async getRateInfos(): Promise<ApiResponse<RateInfoOverview>> {
        try {
            const today = new Date().toISOString();
            const fromDate = moment(today).subtract(7, 'days').toDate().toISOString();

            const result = await this.request<RateInfoOverview>(`/RateInfos?from=${fromDate}&to=${today}`);
            return result;
        } catch (e) {
            console.error('Error fetching rateInfos', e);
            this.handleError?.('Error fetching rateInfos', e);
            return { data: null, status: 500 };
        }
    }

    public async getPurchaseOrders(searchCriteria: PurchaseOrderSearchCriteria, page: number, pageSize: number): Promise<ApiResponse<PaginationResult<PurchaseOrder>>> {
        try {
            const result = await this.request<PaginationResult<PurchaseOrder>>(`/PurchaseOrders?page=${page}&pageSize=${pageSize}`,
                { method: 'POST', body: searchCriteria }
            );
            return result;
        } catch (e) {
            console.error('Error fetching purchase orders', e);
            this.handleError?.('Error fetching purchase orders', e);
            return { data: null, status: 500 };
        }
    }

    public async getPurchaseOrderNeedsAttentionCount(): Promise<ApiResponse<number>> {
        try {
            const result = await this.request<number>(`/PurchaseOrders/count/needs-attention`,
                { method: 'get' }
            );
            return result;
        } catch (e) {
            console.error('Error fetching purchase orders that need attention count', e);
            this.handleError?.('Error fetching purchase orders that need attention count', e);
            return { data: null, status: 500 };
        }
    }

    public async getRmas(searchCriteria: RmaSearchCriteria, page: number, pageSize: number): Promise<ApiResponse<PaginationResult<Rma>>> {
        try {
            const result = await this.request<PaginationResult<Rma>>(`/Rmas?page=${page}&pageSize=${pageSize}`,
                { method: 'POST', body: searchCriteria }
            );
            return result;
        } catch (e) {
            console.error('Error fetching RMAs', e);
            this.handleError?.('Error fetching RMAs', e);
            return { data: null, status: 500 };
        }
    }

    public async getRmaById(id: string): Promise<ApiResponse<Rma>> {
        try {
            const result = await this.request<Rma>(`/Rmas/${id}`);
            return result;
        } catch (e) {
            console.error('Error fetching RMA', e);
            this.handleError?.('Error fetching RMA', e);
            return { data: null, status: 500 };
        }
    }

    public async getPurchaseOrderById(id: string): Promise<ApiResponse<PurchaseOrder>> {
        try {
            const result = await this.request<PurchaseOrder>(`/PurchaseOrders/${id}`);
            return result;
        } catch (e) {
            console.error('Error fetching purchase order', e);
            this.handleError?.('Error fetching purchase order', e);
            return { data: null, status: 500 };
        }
    }

    public async createManualPurchaseOrder(
        supplierCode: string,
        requisitionIds: string[],
        defaultIntegration: boolean = true,
        emailAddressOverride?: string | null,
        deliveryDateOverride?: Date | null
    ): Promise<ApiResponse<PurchaseOrder>> {
        const loadingMessage = this.handleLoading?.('Creating manual purchase order');
        try {
            let path = `/PurchaseOrders/Manual?supplierMontaCode=${supplierCode}&submitEmail=${defaultIntegration}`
            if (emailAddressOverride) {
                path += `&emailAddress=${encodeURIComponent(emailAddressOverride)}`
            }
            if (deliveryDateOverride) {
                path += `&deliveryDateOverride=${deliveryDateOverride.toISOString()}`
            }
            const result = await this.request<PurchaseOrder>(path, {
                method: 'POST',
                body: requisitionIds
            });
            this.handleSuccess?.(`Purchase order: '${result.data?.purchaseOrderNumber}' created`);
            return result;
        } catch (e) {
            console.error(`Error creating manual purchase order for supplier with montaCode: '${supplierCode}'`, e);
            this.handleError?.('Error creating manual purchase order', e);
            return { data: null, status: 500 };
        } finally {
            loadingMessage?.remove();
        }
    }

    public async downloadPostponedFulfillments(startDate: string, endDate: string): Promise<void> {
        try {
            const response = await this.request<{ csvData: string }>(`/MontaOrders/PostponedFulfillments/download?startDate=${startDate}&endDate=${endDate}`);

            // Check if response and csvData is not null before proceeding
            if (response.data && response.data.csvData) {
                const csv = response.data.csvData;

                const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `postponed-fulfillments-${startDate}-to-${endDate}.csv`);
                document.body.appendChild(link);
                link.click();

                link.parentNode?.removeChild(link);
                window.URL.revokeObjectURL(url);
            } else {
                console.error('No data to download');
            }
        } catch (e) {
            console.error('Error downloading postponed fulfillments', e);
            this.handleError?.('Error downloading postponed fulfillments', e);
        }
    }

    public async mailPurchaseOrder(purchaseOrderNumber: string | number, emailParticipants: string[]): Promise<void> {
        const loadingMessage = this.handleLoading?.(`Mailing purchase order: '${purchaseOrderNumber}'`);

        try {
            const path = `/PurchaseOrders/${purchaseOrderNumber}/email`;

            await this.request(path, {
                method: 'POST',
                body: emailParticipants
            });

            this.handleSuccess?.(`Purchase order: '${purchaseOrderNumber}' mailed successfully`);
        } catch (e) {
            console.error('Error mailing purchase order', e);
            this.handleError?.('Error mailing manual purchase order', e);
        } finally {
            loadingMessage?.remove();
        }
    }

    public async markPurchaseOrderAsSubmitted(purchaseOrderNumber: string | number): Promise<PurchaseOrder | null> {
        const loadingMessage = this.handleLoading?.(`Marking purchase order as submitted: '${purchaseOrderNumber}'`);

        try {
            const path = `/PurchaseOrders/${purchaseOrderNumber}/markAsSubmitted`;

            const result = await this.request<PurchaseOrder>(path, {
                method: 'GET'
            });

            this.handleSuccess?.(`Purchase order: '${purchaseOrderNumber}' marked as submitted successfully`);
            return result.data;
        } catch (e) {
            console.error('Error marking purchase order as submitted', e);
            this.handleError?.('Error marking purchase order as submitted', e);
            return null;
        } finally {
            loadingMessage?.remove();
        }
    }

    public async resolveSalesOrderErrorEvent(mageReference: string, errorId: string, author: string): Promise<ErrorEventModel | null> {
        const loadingMessage = this.handleLoading?.(`Resolving error event for order: '${mageReference}'`);

        try {
            const path = `/SalesOrders/${mageReference}/errorEvents/${errorId}/resolve`;

            const response = await this.request<ErrorEventModel>(path, {
                method: 'PUT',
                body: author
            });

            this.handleSuccess?.(`Resolved error event successfully`);
            return response.data;
        } catch (e) {
            console.error('Error resolving error event', e);
            this.handleError?.('Error resolving error event', e);
            return null;
        } finally {
            loadingMessage?.remove();
        }
    }

    public async resolveAllSalesOrderErrorEvents(mageReference: string, author: string): Promise<SalesOrder | null> {
        const loadingMessage = this.handleLoading?.(`Resolving all error events for order: '${mageReference}'`);

        try {
            const path = `/SalesOrders/${mageReference}/errorEvents/resolveAll`;

            const response = await this.request<SalesOrder>(path, {
                method: 'PUT',
                body: author
            });

            this.handleSuccess?.(`Resolved all error events successfully`);
            return response.data;
        } catch (e) {
            console.error('Error resolving all error events', e);
            this.handleError?.('Error resolving all error events', e);
            return null;
        } finally {
            loadingMessage?.remove();
        }
    }

    public async resolvePurchaseOrderErrorEvent(purchaseOrderNumber: number, errorId: string, author: string): Promise<ErrorEventModel | null> {
        const loadingMessage = this.handleLoading?.(`Resolving error event for Purchase Order: '${purchaseOrderNumber}'`);

        try {
            const path = `/PurchaseOrders/${purchaseOrderNumber}/errorEvents/${errorId}/resolve`;

            const response = await this.request<ErrorEventModel>(path, {
                method: 'PUT',
                body: author
            });

            this.handleSuccess?.(`Resolved error event successfully`);
            return response.data;
        } catch (e) {
            console.error('Error resolving error event', e);
            this.handleError?.('Error resolving error event', e);
            return null;
        } finally {
            loadingMessage?.remove();
        }
    }

    public async resolveAllPurchaseOrderErrorEvents(purchaseOrderNumber: number, author: string): Promise<PurchaseOrder | null> {
        const loadingMessage = this.handleLoading?.(`Resolving all error events for Purchase Order: '${purchaseOrderNumber}'`);

        try {
            const path = `/PurchaseOrders/${purchaseOrderNumber}/errorEvents/resolveAll`;

            const response = await this.request<PurchaseOrder>(path, {
                method: 'PUT',
                body: author
            });

            this.handleSuccess?.(`Resolved all error events successfully`);
            return response.data;
        } catch (e) {
            console.error('Error resolving all error events', e);
            this.handleError?.('Error resolving all error events', e);
            return null;
        } finally {
            loadingMessage?.remove();
        }
    }

    public async resolveInboundForecastGroupErrorEvent(mongoId: string, errorId: string): Promise<ErrorEventModel | null> {
        const loadingMessage = this.handleLoading?.(`Resolving error event for Inbound: '${mongoId}'`);

        try {
            const path = `/InboundForecastGroups/${mongoId}/errorEvents/${errorId}/resolve`;

            const response = await this.request<ErrorEventModel>(path, {
                method: 'PUT'
            });

            this.handleSuccess?.(`Resolved error event successfully`);
            return response.data;
        } catch (e) {
            console.error('Error resolving error event', e);
            this.handleError?.('Error resolving error event', e);
            return null;
        } finally {
            loadingMessage?.remove();
        }
    }

    public async resolveAllInboundForecastGroupErrorEvents(mongoId: string): Promise<InboundForecastGroup | null> {
        const loadingMessage = this.handleLoading?.(`Resolving all error events for Inbound: '${mongoId}'`);

        try {
            const path = `/InboundForecastGroups/${mongoId}/errorEvents/resolveAll`;

            const response = await this.request<InboundForecastGroup>(path, {
                method: 'PUT'
            });

            this.handleSuccess?.(`Resolved all error events successfully`);
            return response.data;
        } catch (e) {
            console.error('Error resolving all error events', e);
            this.handleError?.('Error resolving all error events', e);
            return null;
        } finally {
            loadingMessage?.remove();
        }
    }

    public async getStockBySku(sku: string): Promise<Stock | null> {
        try {
            const result = await this.request<Stock>(`/Stocks/${encodeURIComponent(sku)}`);
            return result.data;
        } catch (e) {
            console.error('Error fetching stock', e);
            this.handleError?.(`Error fetching stock with sku: '${sku}'`);
            return null;
        }
    }

    public async getOpenMontaOrdersBySku(sku: string): Promise<MontaOrder[]> {
        try {
            const result = await this.request<MontaOrder[]>(`/MontaOrders/open?sku=${encodeURIComponent(sku)}`);
            return result.data ?? [];
        } catch (e) {
            console.error('Error fetching open monta orders by sku', e);
            this.handleError?.('Error fetching open monta orders by sku', e);
            return [];
        }
    }

    public async getOpenInboundForecastGroupsBySku(sku: string): Promise<InboundForecastGroup[]> {
        try {
            const result = await this.request<InboundForecastGroup[]>(`/InboundForecastGroups/open?sku=${encodeURIComponent(sku)}`);
            return result.data ?? [];
        } catch (e) {
            console.error('Error fetching open inbound forecast groups by sku', e);
            this.handleError?.('Error fetching open inbound forecast groups by sku', e);
            return [];
        }
    }

    public async addOrUpdateInboundComment(inboundGroupId: string, comment: CommentModel): Promise<CommentModel[]|null> {
        const loadingMessage = this.handleLoading?.('Adding comment');
        try {
            const result = await this.request<CommentModel[]>(`/InboundForecastGroups/${inboundGroupId}/comments`, {
                method: 'POST',
                body: comment
            });
            this.handleSuccess?.('Comment added');
            return result.data;
        } catch (e) {
            console.error('Error adding comment', e);
            this.handleError?.('Error adding comment', e);
            return null;
        } finally {
            loadingMessage?.remove();
        }
    }

    public async deleteInboundComment(inboundGroupId: string, commentId: number): Promise<CommentModel[]|null> {
        const loadingMessage = this.handleLoading?.('Deleting comment');
        try {
            const result = await this.request<CommentModel[]>(`/InboundForecastGroups/${inboundGroupId}/comments/${commentId}`, {
                method: 'DELETE'
            });
            this.handleSuccess?.('Comment deleted');
            return result.data;
        } catch (e) {
            console.error('Error deleting comment', e);
            this.handleError?.('Error deleting comment', e);
            return null;
        }
        finally {
            loadingMessage?.remove();
        }
    }

    public async printNewShippingLabel(salesOrderReference: string, address: Address|null, printerId: number): Promise<void>
    {
        const loadingMessage = this.handleLoading?.('Printing new shipping label');

        try {
            const path = `/SalesOrders/${salesOrderReference}/printShippingLabel?printerId=${printerId}`;

            const result = await this.request(path, {
                method: 'POST',
                body: address
            });

            if (result.status === StatusCodes.OK) {
                this.handleSuccess?.('Shipping label printed');
            } else {
                this.handleError?.('Unexpected response from server', result);
            }
        } catch (e) {
            console.error('Error printing new shipping label', e);
            this.handleError?.('Error printing new shipping label', e);
        } finally {
            loadingMessage?.remove();
        }
    }

    public async getAutoSplitState(): Promise<ApiResponse<AutoSplitStateDto|null>> {
        try {
            return await this.request<AutoSplitStateDto|null>(
                '/AutoSplit',
                {method: 'GET'}
            );
        } catch (e) {
            console.error('Error fetching rateInfos', e);
            this.handleError?.('Error fetching rateInfos', e);
            return { data: null, status: 500 };
        }
    }

    public async startAutoSplitPreparation() : Promise<void> {
        const loadingMessage = this.handleLoading?.('Starting AutoSplit preparation');

        try {
            await this.request('/AutoSplit/prepare', {method: 'POST'});
            this.handleSuccess?.('AutoSplit preparation started');
        } catch (e) {
            console.error('Error starting AutoSplit preparation', e);
            this.handleError?.('Error starting AutoSplit preparation', e);
        } finally {
            loadingMessage?.remove();
        }
    }

    public async startAutoSplit(splitAmount: number) : Promise<void> {
        const loadingMessage = this.handleLoading?.('Starting AutoSplit');

        try {
            await this.request(`/AutoSplit/start?splitAmount=${splitAmount}`, {method: 'POST'});
            this.handleSuccess?.('AutoSplit started');
        } catch (e) {
            console.error('Error starting AutoSplit', e);
            this.handleError?.('Error starting AutoSplit', e);
        } finally {
            loadingMessage?.remove();
        }
    }

    public async cancelAutoSplit() : Promise<void> {
        const loadingMessage = this.handleLoading?.('Cancelling AutoSplit');

        try {
            await this.request(`/AutoSplit/cancel`, {method: 'DELETE'});
            this.handleSuccess?.('AutoSplit preparation cancelled');
        } catch (e) {
            console.error('Error cancelling AutoSplit preparation', e);
            this.handleError?.('Error cancelling AutoSplit preparation', e);
        } finally {
            loadingMessage?.remove();
        }
    }

    public async undoMontaOrderConsolidation(mageReference: string, author: string): Promise<ApiResponse<SalesOrderView>> {
        const loadingMessage = this.handleLoading?.(`Undo consolidation for order: '${mageReference}'`);

        try {
            const path = `/SalesOrders/${mageReference}/undoConsolidation`;

            const response = await this.request<SalesOrderView>(path, {
                method: 'POST',
                body: author
            });

            this.handleSuccess?.(`Undo consolidation successfully`);
            return response;
        } catch (e) {
            console.error('Error undo consolidation', e);
            this.handleError?.('Error undo consolidation', e);
            return { data: null, status: 500 };
        } finally {
            loadingMessage?.remove();
        }
    }
}

export default ApiClient
